<script>

import Dashboard from "@/views/fumax/Dashboard"
import Device from "@/views/fumax_snow/Device";

export default {
  name: "FumaxSnowBoard",
  extends: Dashboard,
  components: {
    Device,
  }
};
</script>
